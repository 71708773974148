<template>
  <div>
    <AppHeader />
    <div class="container-fluid m-0 p-0">
      <div class="row justify-content-center">
        <div class="col-7 t-center">
          <h1>Impressum</h1>
        </div>
      </div>

      <div class="row bg-white pt-5 pb-5 border-bottom justify-content-center">
        <div class="col-4 p-0 t-center">
          <p>
            {{ $t("adresseName") }}<br />
            {{ $t("adresseStrasse") }}<br />
            {{ $t("adresseOrt") }}
          </p>

          <p><i class="far fa-envelope-open pe-2"></i>{{ $t("adresseMail") }}@{{ $t("adresseMailDomain") }}</p>
          <p><i class="fas fa-phone pe-2"></i>{{ $t("adresseTel") }}</p>
          <p><i class="fas fa-fax pe-2"></i>{{ $t("adresseFax") }}</p>
          <h4>{{ $t("steuerId") }}</h4>
          <p>{{ $t("steuerNummer") }}</p>
        </div>
      </div>
    </div>
    <div
      id="stickybar"
      class="hidden-xs wow slideInRight animated"
      data-wow-delay="2s"
      style="
        visibility: visible;
        -webkit-animation-delay: 2s;
        -moz-animation-delay: 2s;
        animation-delay: 2s;
      "
    >
      <ul>
        <li>
          <a
            href="kontakt/"
            class=""
            style="width: 70px; height: 70px; padding-top: 10px"
          >
            <i class="fa fa-phone fa-2x" style="font-size: 1.3em"></i>
            <span style="height: 70px; padding-top: 10px; margin-top: -10px">{{
              $t("adresseTel")
            }}</span>
          </a>
        </li>
        <li>
          <a
            :href="'mailto:' + $t('adresseMail')+'@'+ $t('adresseMailDomain')"
            class=""
            style="width: 70px; height: 70px; padding-top: 10px"
            ><i class="far fa-envelope-open fa-2x" style="font-size: 1.3em"></i>
            <span style="height: 70px; padding-top: 10px; margin-top: -10px">
              {{ $t("adresseMail") }}@{{ $t("adresseMailDomain") }}
            </span></a
          >
        </li>
      </ul>
    </div>
  </div>
  <AppFooter />
</template>

<script>
import AppHeader from "../components/AppHeader2.vue";
import AppFooter from "../components/AppFooter.vue";

export default {
  name: "ImpressumPage",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    AppHeader,
    AppFooter,
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
