<template>
  <DefaultLayout />
  
  <widget-container-modal />
</template>

<script>
import DefaultLayout from './layouts/DefaultLayout.vue'
    import {container} from "jenesius-vue-modal";

export default {
  name: 'App',
  components: {
    DefaultLayout,
    WidgetContainerModal: container
  }
}
</script>





<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
nav {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

</style>
