<template>
  <div class="backgrund3">
    <AppHeader class="qdsHeader" />
    <div class="container">
      <h1 class="mainTitel einblenden1">Q D S</h1>
      <h3 class="mainTitel3 einblenden2">
        Qualitätssicherungs-Software <br />
        <br />
        Passend für Ihr Unternehmen
      </h3>
    </div>
  </div>
  <div class="container">
    <AppHeader2 />
    <section class="max-w-grid-width text-start">
      <div class="row">
        <div class="col-md-5">
          <h2 class="titelRahmen">Qualitäts-Dokumentation-System</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 t-center p-4">
          <h2 class="pb-md-4">
            <span class="fw-bold">QDS</span> ist ein Web-basiertes System zur
            produktionsbegleitenden Dokumentation von Prüf- und Testergebnissen.
          </h2>
          <h4>
            Einfach in Ihrer vorhandenen Netzwerkstruktur zu etablieren, bietet es die
            Möglichkeit an jeden Arbeitsplatz egal ob PC oder Tablet zu arbeiten.
          </h4>
          <p>
            Mitarbeiter und Arbeitsplatz bezogene Rechte ermöglichen eine einfache
            Administration von QDS.
          </p>
          <p>Über Barcodes kann jedes Teil in der Fertigung sicher erkannt werden.</p>
          <p>
            Organisieren Sie jeden weiteren Produktionsschritt an Hand von Prüfergebnisen.
            Leiten Sie einzelne Teile zur Nachbearbeitung um, wenn die Vorgaben nicht
            eingehalten wurden, oder Beenden Sie den Produktionsablauf für defekte Teile.
          </p>
          <ul>
            <li>Dokumentieren Sie wichtige Kennzahlen der Produktion.</li>
            <li>
              Leiten Sie einzelne Teile zur Nachbearbeitung um, wenn die Vorgaben nicht
              eingehalten wurden.
            </li>
            <li>Beenden Sie den Produktionsablauf für defekte Teile.</li>
          </ul>
          <P
            >Erhalten sie einen detailierten Überblick über die Prüfergebnisse jedes
            einzelnen Prüfpunktes und jeder einzelnen Tätigkeit.</P
          >
          <P
            >Reduzieren Sie teure Nacharbeiten und Ausschüsse durch Erkennung von
            Schwachstellen in Ihrem Produktionsablauf.</P
          >
        </div>
      </div>

      <div class="row justify-content-center pt-md-5">
        <div class="col-md-3 t-center mx-5 p-md-4 schatten">
          <img src="../assets/qds/modell.png" height="100" class="p-md-2" />
          <p>
            Für jedes Modell in Ihrer Fertigung können eigene Szenarien angelegt werden.
          </p>
          <p>Behalten Sie den Überblick über Ihre Fertigung und Qualitätsstandards.</p>
        </div>
        <div class="col-md-3 t-center mx-5 p-md-4 schatten">
          <img src="../assets/qds/fertigung.png" height="100" class="p-2" />
          <p>
            Für jeden Fertigungsschritt können beliebig viele Prüfungen festgelegt werden.
          </p>
          <p>Entscheiden Sie über die Häufigkeit der Prüfung.</p>
        </div>
        <div class="col-md-3 t-center mx-5 p-md-4 schatten">
          <img src="../assets/qds/taetigkeiten.png" height="100" class="p-2" />
          <p>
            Erstellen Sie ihre Standartprüfverfahren fest und weisen sie diese Tätigkeiten
            und Modellen zu.
          </p>
          <p>Legen Sie Grenzwertde und Prüfvorgaben individuell für jedes Modell fest.</p>
        </div>
      </div>

      <div class="row pt-5">
        <div class="col-md-12">
          <h2 class="titelRahmen">Produktionsbegleitende Prüfungsfolgeplanung</h2>
        </div>
      </div>
      <div class="row pt-5">
        <div class="col-md-4">
          <img
            src="../assets/qds/Produktionsbeginn.png"
            width="450"
            class="p-2 border NurGrossansicht rundeEcken"
          />
          <img
            src="../assets/qds/Produktionsbeginn.png"
            width="300"
            class="p-2 border NurKleinansicht rundeEcken"
          />
        </div>
        <div class="col-md-5 offset-md-1">
          <h4>Feinteilige Konfiguration</h4>
          <ul>
            <li>Modellspezifische Planung</li>
            <li>Produktionsbegleitende Prüfungen</li>
            <li>Flusssteuerung bei Qualitätsmängel</li>
            <li>Detailübersicht der Schwachstellen in der Produktionskette</li>
            <li>
              Verschiedene Prüfhäufigkeiten
              <ul>
                <li>
                  Immer Prüfen - Jedes Teil wird an diesem Produktionsschritt geprüft
                </li>
                <li>
                  Regelmäßig Prüfen - es wird z.B. jedes 10. Teil an dieser Prüfstelle
                  geprüft
                </li>
                <li>
                  Stichpunktprüfung - es wird zufällig ein Teill innerhalb von z.B. 50
                  Produktionen geprüft
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="row pt-5">
        <div class="col-md-12">
          <h2 class="titelRahmen">Kostenreduzierung</h2>
        </div>
      </div>
      <div class="row my-md-5">
        <div class="col-md-4">
          <img
            src="../assets/qds/Ausschussreduzierung.png"
            width="450"
            class="p-2 NurGrossansicht rundeEcken"
          />
          <img
            src="../assets/qds/Ausschussreduzierung.png"
            width="300"
            class="p-2 NurKleinansicht rundeEcken"
          />
        </div>
        <div class="col-5 offset-1">
          <h4>Aussagekräftige Auswertungen</h4>
          <ul>
            <li>Umfangreiche Auswertungen</li>
            <li>Auffinden von Produktionsrisiken</li>
            <li>Reduzierung von Fehlproduktionen</li>
            <li>Minimierung von Ausschuss</li>
            <li>Motivationssteigerung der Mitarbeiter</li>
            <li>Steigerung des Gewinns</li>
          </ul>
        </div>
      </div>
      <div class="row pt-5">
        <div class="col-md-12">
          <h2 class="titelRahmen">Produktionsbegleitende Dokumentation</h2>
        </div>
      </div>
      <div class="row my-md-5">
        <div class="col-md-4">
          <img
            src="../assets/qds/zertifikat.png"
            width="450"
            class="NurGrossansicht rundeEcken p-2"
          />
          <img
            src="../assets/qds/zertifikat.png"
            width="300"
            class="NurKleinansicht rundeEcken p-2"
          />
        </div>

        <div class="col-5 offset-1">
          <h4>Umfangreiche Dokumentation</h4>
          <ul>
            <li>Prüfnachweise für Einzelteile</li>
            <li>Zertifikaterstellung als PDF</li>
            <li>Individuelles Design</li>
          </ul>
        </div>
      </div>
      <div class="row bg-white my-md-5 justify-content-center">
        <div class="col-12 py-4">
          <h2>QDS wird für jeden Kunden individuell auf seine Bedürfnisse angepasst</h2>
        </div>

        <div class="col-md-6 text-center NurGrossansicht">
          <video controls width="800" class="videoClassGross videosize">
            <source src="../assets/qds/QDS.mp4" type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
        <div class="col-md-6 text-center NurKleinansicht">
          <video controls width="300" class="videoClassGross videosize">
            <source src="../assets/qds/QDS.mp4" type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
      </div>
    </section>
    <section>
      <router-link to="/Kontakt" class="btn btn-primary"
        >Kontaktieren Sie uns jetzt!</router-link
      >
    </section>
    <section>
      <AppFooter />
    </section>
  </div>

  <stickybarTelMail />
  <stickyPfeil />
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AppHeader2 from "../components/AppHeader2.vue";
import AppFooter from "../components/AppFooter.vue";
import stickybarTelMail from "../components/stickybarTelMail.vue";
import stickyPfeil from "../components/stickyPfeil.vue";

export default {
  name: "StammQDSPagedaten",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    AppHeader,
    AppHeader2,
    AppFooter,
    stickybarTelMail,
    stickyPfeil,
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
