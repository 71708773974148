import { createApp } from "vue";
import App from "./App.vue";

import { createPinia } from "pinia";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
// BootstrapVue-Komponenten explizit importieren
import * as BootstrapVueNext from "bootstrap-vue-next";

import i18n from "./i18n"; // Importiere die i18n-Konfiguration
import { createRouter, createWebHistory } from "vue-router";

// Videoplayer
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

//cookies
import VueCookies from 'vue3-cookies';
//fortawesome
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

//Mouse effekte
import { MotionPlugin } from '@vueuse/motion';

//modals
import JenesiusVueModal from 'jenesius-vue-modal';

// Importiere deine Komponenten
import Home from "./views/MainPage.vue";
import Datenschutz from "./views/DatenschutzPage.vue";
import Impressum from "./views/ImpressumPage.vue";
import Kontakt from "./views/KontaktPage.vue";
import software from "./views/SoftwarePage.vue";
import qds from "./views/QDSPage.vue";
import web from "./views/WebPage.vue";
import shop from "./views/ShopPage.vue";
// Definiere die Routen
const routes = [
  { path: "/", component: Home },
  { path: "/datenschutz", component: Datenschutz },
  { path: "/impressum", component: Impressum },
  { path: "/kontakt", component: Kontakt },
  { path: "/software", component: software },
  { path: "/qds", component: qds },
  { path: "/web", component: web },
  { path: "/shop", component: shop },
];

// Erstelle den Router
// const router = createRouter({
//   history: createWebHistory(),
//   routes,
// });
const router = createRouter({
  history: createWebHistory(),
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, ...args) {
    
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    return { top: 0 };
  },
});

// Globale CSS-Datei importieren
import "./assets/styles.css";
import "./assets/custom-bootstrap.scss";

// App mit Router verbinden
const app = createApp(App);

for (const componentKey in BootstrapVueNext) {
  app.component(componentKey, BootstrapVueNext[componentKey]);
}

app.use(createPinia()); // Pinia aktivieren
// Axios global verfügbar machen
app.config.globalProperties.$axios = axios;
app.use(i18n); // i18n aktivieren
app.use(router);
app.use(VuePlyr, {
  plyr: {}
});
app.use(VueCookies, { expireTimes: "90d" });
app.use(JenesiusVueModal);
app.use(MotionPlugin);
app.mount("#app");
