<template>
  <div class="backgrund2">
    <AppHeader />
    <div class="container">
      <h1 class="mainTitel einblenden1">Softwarelösungen</h1>
      <h3 class="mainTitel3 einblenden2">
        Individualsoftware von PILLARSOFT <br />
        <br />
        Passend für Ihr Unternehmen
      </h3>
    </div>
  </div>
  <div class="container">
    <AppHeader2 />
    <section class="max-w-grid-width text-start">
      <div class="row">
        <div class="col-md-8 p-md-5">
          <h2 class="titelRahmen">
            Individuelle Softwarelösungen – Passgenau für Ihr Unternehmen
          </h2>
          <h4 class="py-md-4">
            Warum Individualsoftware die beste Wahl für Ihr Unternehmen ist?
          </h4>
          <p>
            Jedes Unternehmen hat einzigartige Prozesse, Anforderungen und Ziele.
            Standardsoftware bietet oft eine generische Lösung, die nicht genau auf die
            Bedürfnisse Ihres Unternehmens zugeschnitten ist. Das führt zu Ineffizienz,
            unnötigen Kosten und Einschränkungen in Ihrer digitalen Infrastruktur.
          </p>
          <p>
            Mit maßgeschneiderter Individualsoftware erhalten Sie eine Lösung, die genau
            auf Ihre Geschäftsprozesse abgestimmt ist, flexibel wächst und langfristig
            wirtschaftlicher ist als Standardlösungen mit Lizenzgebühren und begrenzten
            Anpassungsmöglichkeiten.
          </p>
        </div>
        <div class="col-md-4">
          <img src="../assets/software.jpg" class="img-fluid rundeEcken" alt="Software" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 py-2 p-md-5">
          <h2 class="titelRahmen">Ihre Vorteile</h2>
          <h4 class="py-md-3">Perfekte Anpassung an Ihre Geschäftsprozesse</h4>
          <p>
            Standardsoftware zwingt Unternehmen oft dazu, ihre bewährten Abläufe an die
            Software anzupassen. Mit Individualsoftware passiert das Gegenteil: Die
            Software wird speziell für Ihre Anforderungen entwickelt, sodass sie Ihre
            Prozesse ideal unterstützt und optimiert.
          </p>
          <h4 class="py-md-3">Höhere Effizienz und Produktivität</h4>

          <p>
            - Keine unnötigen Funktionen, die Ihr System überladen. <br />
            - Benutzerfreundliches Design, das genau auf Ihre Teams zugeschnitten ist.
            <br />
            - Automatisierung von Prozessen zur Reduzierung manueller Aufgaben. <br />
            - Schnellere und präzisere Datenverarbeitung ohne Kompromisse.
          </p>
          <h4 class="py-md-3">Skalierbarkeit & Zukunftssicherheit</h4>

          <p>
            Ihr Unternehmen wächst und verändert sich – und Ihre Software muss mitwachsen.
            <br />
            - Modularer Aufbau: Neue Funktionen können einfach integriert werden. <br />
            - Keine Abhängigkeit von Drittanbietern oder Lizenzmodellen. <br />
            - Technologisch flexibel: Integration in bestehende IT-Systeme und
            Cloud-Lösungen möglich.
          </p>
          <h4 class="py-md-3">Hohe Sicherheit & Datenschutz</h4>

          <p>
            Individuelle Software reduziert Sicherheitsrisiken durch gezielte
            Implementierung von Schutzmechanismen: <br />
            - Daten bleiben unter Ihrer Kontrolle, ohne Drittanbieter-Zugriffe. <br />
            - Sicherheitsstandards können individuell angepasst und regelmäßig
            aktualisiert werden. <br />
            - Minimierung von Angriffspunkten, da keine allgemeingültigen Exploits
            existieren wie bei Standardsoftware.
          </p>
          <h4 class="py-md-3">Langfristige Kostenersparnis</h4>
          <p>
            Obwohl Individualsoftware in der Entwicklung oft höhere Investitionen
            erfordert, ist sie auf lange Sicht deutlich kosteneffizienter: <br />
            - Keine laufenden Lizenzkosten für überflüssige Funktionen. <br />
            - Keine Abhängigkeit von Herstellern – Sie bestimmen, wann Updates und
            Erweiterungen erfolgen. <br />
            - Bessere Integration mit vorhandenen Systemen, wodurch Umstellungskosten
            gespart werden.
          </p>
        </div>
        <div class="col-md-6 p-md-5">
          <h2 class="titelRahmen">Unsere Leistungen</h2>
          <h4 class="py-md-3">Bedarfsanalyse & Beratung</h4>

          <p>
            Wir analysieren Ihre Anforderungen und erarbeiten gemeinsam die optimale
            Lösung.
          </p>
          <h4 class="py-md-3">Softwareentwicklung nach Maß</h4>
          <p>
            Von der ersten Idee bis zur fertigen Lösung – unsere Experten programmieren
            nach höchsten Standards.
          </p>
          <h4 class="py-md-3">Integration & Schnittstellenentwicklung</h4>
          <p>
            Unsere Softwarelösungen lassen sich nahtlos in bestehende Systeme integrieren.
          </p>
          <h4 class="py-md-3">Wartung & Weiterentwicklung</h4>
          <p>
            Unsere Softwarelösungen lassen sich nahtlos in bestehende Systeme integrieren.
          </p>
          <h4 class="py-md-3">Schulung & Support</h4>
          <p>
            Damit Ihre Mitarbeiter die Software optimal nutzen können, bieten wir
            Schulungen und technischen Support.
          </p>
          <h2>Warum mit uns zusammenarbeiten?</h2>
          <ul>
            <li class="py-md-3">
              Erfahrung & Kompetenz – Wir haben jahrelange Erfahrung in der Entwicklung
              individueller Softwarelösungen für verschiedenste Branchen.
            </li>
            <li class="py-md-3">
              Innovative Technologien – Wir nutzen moderne Technologien und agile
              Methoden, um leistungsstarke und zukunftssichere Software zu entwickeln.
            </li>
            <li class="py-md-3">
              Partnerschaftliche Zusammenarbeit – Ihr Erfolg ist unser Ziel. Wir begleiten
              Sie von der ersten Idee bis zur langfristigen Betreuung Ihrer Software.
            </li>
            <li class="py-md-3">
              Nachhaltige Lösungen – Unsere Software wächst mit Ihrem Unternehmen und
              bleibt anpassbar, flexibel und wirtschaftlich.
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 p-md-5">
          <h2 class="titelRahmen py-md-3">Wann ist Individualsoftware die beste Wahl?</h2>
          <ul class="py-md-3">
            <li class="py-md-3">
              Wenn Standardlösungen nicht Ihren Anforderungen entsprechen.
            </li>
            <li class="py-md-3">
              Wenn Sie spezifische Prozesse haben, die automatisiert oder optimiert werden
              sollen.
            </li>
            <li class="py-md-3">
              Wenn Sie eine langfristig kosteneffiziente Lösung suchen, ohne
              Lizenzgebühren.
            </li>
            <li class="py-md-3">
              Wenn Ihre IT-Infrastruktur eine flexible und erweiterbare Lösung benötigt.
            </li>
            <li class="py-md-3">
              Wenn Sie mehr Sicherheit und Kontrolle über Ihre Daten wünschen.
            </li>
          </ul>
        </div>
        <div class="col-md-4">
          <img
            src="../assets/Zuverlaessigkeit.jpg"
            alt="QDS"
            class="img-fluid rundeEcken"
          />
        </div>
      </div>
    </section>
    <section>
      <router-link to="/Kontakt" class="btn btn-primary"
        >Kontaktieren Sie uns jetzt!</router-link
      >
    </section>
    <section>
      <AppFooter />
    </section>
  </div>

  <stickybarTelMail />
  <stickyPfeil />
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AppHeader2 from "../components/AppHeader2.vue";
import AppFooter from "../components/AppFooter.vue";
import stickybarTelMail from "../components/stickybarTelMail.vue";
import stickyPfeil from "../components/stickyPfeil.vue";

export default {
  name: "SoftwarePage",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    AppHeader,
    AppHeader2,
    AppFooter,
    stickybarTelMail,
    stickyPfeil,
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
