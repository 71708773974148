<template>
  <div class="layout">
    
    
    <main>
      <router-view></router-view>
    </main>


  </div>
</template>

<script>



export default {
  name: "DefaultLayout",
  components: {

 
  }
};
</script>

