<template>
  <footer>
    
      <Div class="row justify-content-center ">
        <Div class="col-md-11">
          <hr />
        </Div>
      </Div>

      <Div class="row justify-content-center ">
        <Div class="col-md-3">
          <h4 class="fw-bolder">
            <router-link to="/impressum" class="footerLink rundeEckenKlein">{{
              $t("impressum")
            }}</router-link>
          </h4>
        </Div>
        <Div class="col-md-5">
          {{ $t("adresseName") }}, {{ $t("adresseStrasse") }}, {{ $t("adresseOrt")
          }}<br />
          <i class="fas fa-phone ps-4 pe-2"> </i>{{ $t("adresseTel") }},
          <i class="fas fa-fax ps-4 pe-2"> </i>{{ $t("adresseFax") }},
          <i class="far fa-envelope-open ps-4 pe-2"> </i>{{ $t("adresseMail") }}@{{ $t("adresseMailDomain") }}
        </Div>
        <Div class="col-md-3">
          <h4 class="fw-bolder">
            <router-link to="/datenschutz" class="footerLink rundeEckenKlein">{{
              $t("datenschutz")
            }}</router-link>
          </h4>
        </Div>
      </Div>
      <Div class="row justify-content-center">
        <Div class="col-md-3">
          <p>&copy; {{new Date().getFullYear()}} {{ $t("adresseName") }}</p>
        </Div>
      </Div>
      <Div class="row justify-content-center " 
      v-if="HomepageCookiePillarSoft!=$t('adresseName')">
        <Div class="col-md-6">
          Wir setzen auf unserer Website kein herkömmliches Cookie-basiertes Tracking ein.
          Unsere Analysemethoden schützen Ihre Privatsphäre und helfen uns, Ihr
          Nutzererlebnis kontinuierlich zu verbessern.
        </Div>
        <Div class="col-md-3">
          <button
            type="button"
            class="btn btn-outline-danger mx-3"
            @click="openCookieDetail()"
          >
            Details
          </button>
          <button type="button" class="btn btn-danger" @click="setCookie()">
            Hinweis schließen
          </button>
        </Div>
      </Div>
    
  </footer>
</template>

<script>
import ModalCookieDetail from "../components/ModalCookieDetail.vue"
import { openModal } from "jenesius-vue-modal";
//import { computed } from 'vue';

//const currentYear = computed(() => new Date().getFullYear());

export default {

  name: "AppFooter",
  data() {
    return {
      HomepageCookiePillarSoft: "",
    }
  },
  methods: {
    openCookieDetail() {
      openModal(ModalCookieDetail);
    },
    setCookie() {
       // Cookie setzen
    this.$cookies.set('HomepageCookiePillarSoft', this.$t('adresseName'));
    this.HomepageCookiePillarSoft =  this.$t('adresseName');
  },
  },
  mounted() {  
    // Cookie auslesen
    this.HomepageCookiePillarSoft = this.$cookies.get('HomepageCookiePillarSoft');
    console.log('HomepageCookiePillarSoft:', this.HomepageCookiePillarSoft);
  }

}
</script>
