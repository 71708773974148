<template>
    <div
    id="stickybar"
    class="d-none d-sm-block wow slideInRight animated"
    data-wow-delay="2s"
  >
    <ul>
      <li>
        <a
          href="kontakt/"
          class="text-white"
          style="width: 70px; height: 70px; padding-top: 10px"
        >
          <i class="fa fa-phone fa-2x text-white" style="font-size: 1.3em"></i>
          <span style="height: 70px; padding-top: 10px; margin-top: -10px" class="text-white"
            >{{ $t('adresseTel') }}</span
          >
        </a>
      </li>
      <li>
        <a
          :href="'mailto:' + $t('adresseMail')"
          class=""
          style="width: 70px; height: 70px; padding-top: 10px"
          ><i class="far fa-envelope-open fa-2x" style="font-size: 1.3em"></i>
          <span style="height: 70px; padding-top: 10px; margin-top: -10px">
            {{ $t('adresseMail') }}@{{ $t('adresseMailDomain') }}</span
          ></a
        >
      </li>
    </ul>
  </div>

</template>