<template>
  <div class="backgrund4">
    <AppHeader />
    <div class="container">
      <h1 class="mainTitel einblenden1">Webdesign & Shops</h1>
      <h3 class="mainTitel3 einblenden2">Ihr digitaler Erfolg</h3>
    </div>
  </div>
  <div class="container">
    <AppHeader2  class="NurGrossansicht"/>
  <section class="max-w-grid-width text-start py-2">
    <h2 class="mb-md-4 titelRahmen">
      Warum modernes Webdesign für Firmen essenziell ist
    </h2>
    <h4 class="p-2 mb-md-5">
      In der digitalen Welt ist Ihre Website oft der erste Berührungspunkt mit
      potenziellen Kunden.<br /><br />
      Ein modernes Webdesign ist nicht nur eine visuelle Visitenkarte, sondern trägt
      maßgeblich zum Erfolg Ihres Unternehmens bei.
    </h4>
    <div class="row">
      <div class="col-md-8">
        <div class="row mb-4">
          <div class="col-md-3"><h4>Erster Eindruck zählt</h4></div>
          <div class="col-md-8">
            <p>
              Innerhalb weniger Sekunden entscheiden Besucher, ob sie auf Ihrer Website
              bleiben oder nicht. Ein professionelles, ansprechendes Design sorgt für
              Vertrauen und hinterlässt einen positiven ersten Eindruck.
            </p>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-3"><h4>Mobile Nutzerfreundlichkeit</h4></div>
          <div class="col-md-8">
            <p>
              Immer mehr Menschen surfen mit Smartphones und Tablets. Eine responsive
              Website passt sich automatisch an verschiedene Bildschirmgrößen an und sorgt
              für eine optimale Benutzererfahrung.
            </p>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-3"><h4>Bessere Sichtbarkeit durch SEO</h4></div>
          <div class="col-md-8">
            <p>
              Suchmaschinen wie Google bevorzugen gut strukturierte, schnelle und
              nutzerfreundliche Websites. Ein modernes Webdesign hilft, bessere
              Platzierungen in den Suchergebnissen zu erzielen.
            </p>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-3"><h4>Höhere Conversion-Rate</h4></div>
          <div class="col-md-8">
            <p>
              Eine klare, intuitive Navigation und gezielte Call-to-Actions (CTAs) erhöhen
              die Wahrscheinlichkeit, dass Besucher zu Kunden werden. Ein durchdachtes
              Design führt Nutzer gezielt durch Ihre Website.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3"><h4>Stärkung der Markenidentität</h4></div>
          <div class="col-md-8">
            <p>
              Ihr Webdesign spiegelt Ihre Marke wider. Farben, Schriften und
              Designelemente sollten Ihre Werte und Ihr Unternehmen professionell
              repräsentieren, um einen Wiedererkennungswert zu schaffen.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <img src="../assets/webshop.jpg" alt="QDS" class="img-fluid rundeEcken" />
      </div>
    </div>
  </section>
  <section class="max-w-grid-width my-0 text-start">
    <button type="button" class="btn bg-success text-white fw-bolder px-5"
  @click="openModalKalkulator">Online Kalkulation und Anfrage</button>

  </section>
  
  <section class="max-w-grid-width text-start py-5">
    <div class="container">
      <h2 class="text-center mb-5 titelRahmen">Unsere Leistungen</h2>
      <div class="row justify-content-center">
        <div class="col-md-3 mx-5 p-4 schatten">
          <h4>Webdesign <br />& UX/UI-Design</h4>
          <img src="../assets/Innovation.jpg" alt="QDS" class="img-fluid" />
          <p>
            Ein durchdachtes Design ist der erste Eindruck Ihrer Marke im digitalen Raum.
            Wir gestalten moderne, ansprechende und benutzerfreundliche Websites, die Ihre
            Besucher begeistern und Ihre Marke bestmöglich präsentieren. Unsere Designs
            sind nicht nur visuell ansprechend, sondern auch funktional und
            benutzerfreundlich.
          </p>
        </div>
        <div class="col-md-3 mx-5 p-4 schatten">
          <h4>
            Webentwicklung <br />
            & E-Commerce
          </h4>
          <img src="../assets/Zuverlaessigkeit.jpg" alt="QDS" class="img-fluid" />
          <p>
            Ob Unternehmenswebsite, Online-Shop oder individuelle Webanwendung – wir
            setzen Ihre Ideen mit modernsten Technologien um. Unsere Lösungen sind
            responsiv, sicher und leistungsstark. Besonders im Bereich E-Commerce sorgen
            wir für optimierte Kaufprozesse und eine reibungslose Benutzererfahrung.
          </p>
        </div>
        <div class="col-md-3 mx-5 p-4 schatten">
          <h4>
            SEO <br />
            & Online-Marketing
          </h4>
          <img src="../assets/Massgeschneidert.jpg" alt="QDS" class="img-fluid" />
          <p>
            Eine gut gestaltete Website ist nur der erste Schritt. Damit Sie online
            sichtbar bleiben, optimieren wir Ihre Website für Suchmaschinen und entwickeln
            maßgeschneiderte Online-Marketing-Strategien. Durch gezieltes SEO,
            Content-Marketing und bezahlte Werbekampagnen steigern wir Ihre Reichweite und
            Ihren Umsatz.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="max-w-grid-width my-0 text-start">
    <button type="button" class="btn bg-success text-white fw-bolder px-5"
  @click="openModalKalkulator">Online Kalkulation und Anfrage</button>

  </section>
  <section class="max-w-grid-width text-start py-5">
    <div class="container">
      <h2 class="mb-md-4 titelRahmen">Warum wir?</h2>
      <h4 class="p-2">
        Wir bieten nicht nur erstklassige digitale Lösungen, sondern auch eine enge
        Zusammenarbeit mit unseren Kunden.<br />
        Ihr Erfolg ist unser Ziel!
      </h4>
      <div class="row">
        <div class="col-md-8">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              ✔ <b>Individuelle Lösungen</b><br />
              – Wir verstehen, dass jedes Unternehmen einzigartig ist. Deshalb bieten wir
              maßgeschneiderte Strategien, die genau auf Ihre Anforderungen und Ziele
              abgestimmt sind.
            </li>
            <li class="list-group-item">
              ✔ <b>Modernste Technologien</b><br />
              – Wir setzen auf innovative Technologien und aktuelle Trends, um Ihnen
              zukunftssichere Lösungen zu bieten. Dabei achten wir besonders auf
              Performance, Sicherheit und Skalierbarkeit.
            </li>
            <li class="list-group-item">
              ✔ <b>Kreativität & Innovation</b><br />
              – Unser Team aus Designern, Entwicklern und Marketingexperten denkt über den
              Tellerrand hinaus. Wir schaffen digitale Erlebnisse, die Ihre Kunden
              begeistern und Sie von der Konkurrenz abheben.
            </li>
            <li class="list-group-item">
              ✔ <b>Partnerschaftliche Zusammenarbeit</b><br />
              – Wir sehen uns nicht nur als Dienstleister, sondern als Partner. Eine enge
              Zusammenarbeit und transparente Kommunikation sind für uns der Schlüssel zu
              einem erfolgreichen Projekt.
            </li>
            <li class="list-group-item">
              ✔ <b>Nachhaltiger Erfolg</b><br />
              – Unser Ziel ist nicht nur ein kurzfristiger Erfolg, sondern eine
              langfristige digitale Strategie. Wir helfen Ihnen, Ihre Online-Präsenz
              kontinuierlich zu verbessern und weiterzuentwickeln.
            </li>
          </ul>
        </div>
        <div class="col-md-4">
          <img src="../assets/webdesign.jpg" alt="QDS" class="img-fluid rundeEcken" />
        </div>
      </div>

      <p class="mt-4">
        Lassen Sie uns gemeinsam Ihre digitale Zukunft gestalten! Kontaktieren Sie uns für
        eine unverbindliche Beratung.
      </p>
    </div>
  </section>

  <section class="max-w-grid-width my-0 text-start">
    <button type="button" class="btn bg-success text-white fw-bolder px-5"
  @click="openModalKalkulator">Online Kalkulation und Anfrage</button>

  </section>
  <section>
    <router-link to="/Kontakt" class="btn btn-primary"
      >Kontaktieren Sie uns jetzt!</router-link
    >
  </section>
  
  <section>
    <AppFooter />
  </section>
  </div>
  <stickybarTelMail />
  <stickyPfeil />
</template>

<script>

import AppHeader from "../components/AppHeader.vue";
import AppHeader2 from "../components/AppHeader2.vue";
import AppFooter from "../components/AppFooter.vue";
import stickybarTelMail from "../components/stickybarTelMail.vue";
import stickyPfeil from "../components/stickyPfeil.vue";

import { openModal } from "jenesius-vue-modal";
import ModalKalkulator from "../components/ModalKalkulator.vue";

export default {
  name: "WebPage",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    AppHeader,
    AppHeader2,
    AppFooter,
    stickybarTelMail,
    stickyPfeil,
  },
  methods: 
  {
    async openModalKalkulator() {  
   const modal= await openModal(ModalKalkulator);

      modal.onclose = () => {        
        return true;
      };
    },
  },
  computed: {
     
  },
};
</script>

<style scoped></style>
