<template>
  <nav class="navbar sticky-top navbar-expand-lg">
    
    <div class="container-fluid d-flex justify-content-between align-items-center">
      <!-- Logo -->
      <router-link to="/" class="nav-link d-flex align-items-center ms-md-5">
        <img src="../assets/pillarsoft.png" class="HeaderLogo" alt="LogoLTB" />
      </router-link>

      <!-- Hamburger Menu -->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Navigationsmenü -->
      <div class="collapse navbar-collapse text-white navbarUL" id="navbarSupportedContent">
        <ul class="navbar-nav mb-2 mb-lg-0 text-uppercase">
          <li class="nav-item px-2">
            <router-link to="/" class="nav-link"> HOME </router-link>
          </li>
          <li class="nav-item px-2">
            <router-link to="/software" class="nav-link"> Software </router-link>
          </li>
          <li class="nav-item px-2">
            <router-link to="/qds" class="nav-link"> QDS </router-link>
          </li>
          <li class="nav-item px-2">
            <router-link to="/web" class="nav-link"> Webdesign </router-link>
          </li>
          <li class="nav-item px-2">
            <router-link to="/shop" class="nav-link"> Shops </router-link>
          </li>
          
        </ul>

        <!-- Rechte Navigation -->
        <ul class="navbar-nav mb-2 mb-lg-0 text-uppercase navRechts">
          <!-- <li>
            <button @click="changeLanguage('en')" class="languageNav">
              <img
                src="../assets/united-kingdom.png"
                class="languageIcon"
                alt="Englisch"
              />
            </button>
            <button @click="changeLanguage('de')" class="languageNav">
              <img src="../assets/germany.png" class="languageIcon" alt="Deutsch" />
            </button>
          </li> -->
          <li class="navKontakt px-2">
            <router-link to="/Kontakt" class="nav-link"> Kontakt </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>


<script>
export default {
  name: "TheNavbar",
  computed: {},
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    },
   
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  created() {},
};
</script>
