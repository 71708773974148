<template>
  <div class="backgrund5">
    <AppHeader />
    <div class="container">
      <h1 class="mainTitel einblenden1">Web Shops & E-Commerce</h1>
      <h3 class="mainTitel3 einblenden2">Ihr digitaler Erfolg im Onlinehandel</h3>
    </div>
  </div>

  <div class="container">
    <AppHeader2 />
  <section class="max-w-grid-width text-start py-2">
    <h2 class="mb-4 titelRahmen">Webshop & E-Commerce Design</h2>
    <h4 class="mb-5 p-2">
      Ihr Schlüssel zu mehr Umsatz, Reichweite und Erfolg im Online-Handel. Steigern Sie
      Ihre digitale Präsenz mit einer maßgeschneiderten E-Commerce-Lösung.
    </h4>
    <div class="row">
      <div class="col-md-8">
        <div class="row mb-4">
          <div class="col-md-3"><h4>Individuelles Webshop-Design</h4></div>
          <div class="col-md-8">
            <p>
              Ein einzigartiger Online-Shop ist der Schlüssel zu einer starken Marke. Wir
              erstellen maßgeschneiderte Webshops, die nicht nur optisch beeindrucken,
              sondern auch intuitiv zu bedienen sind. Unsere Designs sind modern,
              funktional und auf eine hohe Benutzerfreundlichkeit ausgerichtet, sodass
              Ihre Kunden mühelos durch Ihren Shop navigieren und einkaufen können.
            </p>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-3"><h4>Conversion-Optimierung</h4></div>
          <div class="col-md-8">
            <p>
              Wir sorgen dafür, dass Ihre Website nicht nur gut aussieht, sondern auch
              erfolgreich verkauft. Durch datengetriebene Analysen, A/B-Tests und gezielte
              Optimierungsstrategien steigern wir Ihre Conversion-Rate. Intelligente
              Call-to-Actions, optimierte Checkout-Prozesse und überzeugende Produktseiten
              führen zu mehr Umsatz und zufriedenen Kunden.
            </p>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-3"><h4>Höhere Conversion-Rate</h4></div>
          <div class="col-md-8">
            <p>
              Eine klare, intuitive Navigation und gezielte Call-to-Actions (CTAs) erhöhen
              die Wahrscheinlichkeit, dass Besucher zu Kunden werden. Ein durchdachtes
              Design führt Nutzer gezielt durch Ihre Website.
            </p>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-3"><h4>SEO & Online-Marketing</h4></div>
          <div class="col-md-8">
            <p>
              Ein Webshop ohne Besucher bringt keinen Erfolg. Mit gezielter
              Suchmaschinenoptimierung (SEO) und effektiven Online-Marketing-Strategien
              sorgen wir für eine hohe Sichtbarkeit Ihres Shops. Wir nutzen unter anderem
              Google Ads, Social Media Marketing und Content-Strategien, um Ihre
              Zielgruppe genau dort zu erreichen, wo sie sich aufhält.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3"><h4>Stärkung der Markenidentität</h4></div>
          <div class="col-md-8">
            <p>
              Ihr Webdesign spiegelt Ihre Marke wider. Farben, Schriften und
              Designelemente sollten Ihre Werte und Ihr Unternehmen professionell
              repräsentieren, um einen Wiedererkennungswert zu schaffen.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <img src="../assets/webshop.jpg" alt="QDS" class="img-fluid rundeEcken" />
      </div>
    </div>
  </section>
  <section class="max-w-grid-width text-start py-5">
    <div class="container">
      <h2 class="text-center mb-5 titelRahmen">Unsere Leistungen</h2>
      <div class="row justify-content-center">
        <div class="col-md-3 mx-5 p-4 schatten">
          <h4>Individuelles <br/>Webshop-Design</h4>
          <img src="../assets/Innovation.jpg" alt="QDS" class="img-fluid" />
          <p>
            Ein einzigartiger Online-Shop ist der Schlüssel zu einer starken Marke. Wir
            erstellen maßgeschneiderte Webshops, die nicht nur optisch beeindrucken,
            sondern auch intuitiv zu bedienen sind. Unsere Designs sind modern, funktional
            und auf eine hohe Benutzerfreundlichkeit ausgerichtet, sodass Ihre Kunden
            mühelos durch Ihren Shop navigieren und einkaufen können.
          </p>
        </div>
        <div class="col-md-3 mx-5 p-4 schatten">
          <h4>Conversion <br/>-Optimierung</h4>
          <img src="../assets/Zuverlaessigkeit.jpg" alt="QDS" class="img-fluid" />
          <p>
            Wir sorgen dafür, dass Ihre Website nicht nur gut aussieht, sondern auch
            erfolgreich verkauft. Durch datengetriebene Analysen, A/B-Tests und gezielte
            Optimierungsstrategien steigern wir Ihre Conversion-Rate. Intelligente
            Call-to-Actions, optimierte Checkout-Prozesse und überzeugende Produktseiten
            führen zu mehr Umsatz und zufriedenen Kunden.
          </p>
        </div>
        <div class="col-md-3 mx-5 p-4 schatten">
          <h4>SEO  <br/>& Online-Marketing</h4>
          <img src="../assets/Massgeschneidert.jpg" alt="QDS" class="img-fluid" />
          <p>
            Ein Webshop ohne Besucher bringt keinen Erfolg. Mit gezielter
            Suchmaschinenoptimierung (SEO) und effektiven Online-Marketing-Strategien
            sorgen wir für eine hohe Sichtbarkeit Ihres Shops. Wir nutzen unter anderem
            Google Ads, Social Media Marketing und Content-Strategien, um Ihre Zielgruppe
            genau dort zu erreichen, wo sie sich aufhält.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="max-w-grid-width text-start py-5">
    
        <h2 class="text-center mb-4 titelRahmen">Unser Prozess</h2>
        <div class="row">
            <div class="col schatten p-4 m-3">
                <h4>1. Beratung</h4>
                <img src="../assets/shopplanung.jpg" alt="QDS" class="img-fluid" />
                <p>Wir analysieren Ihre Bedürfnisse, identifizieren Ihre Zielgruppe und entwickeln eine Strategie, die Ihren Webshop von der Konkurrenz abhebt.</p>
            </div>
            <div class="col schatten p-4 m-3">
                <h4>2. Design</h4>
                <img src="../assets/shopdesign.jpg" alt="QDS" class="img-fluid" />
                <p>Unsere Designer erstellen ein visuell ansprechendes und nutzerfreundliches Layout, das Ihre Marke stärkt und die Konversionsrate steigert.</p>
            </div>
            <div class="col schatten p-4 m-3">
                <h4>3. Entwicklung</h4>
                <img src="../assets/shopprogramierung.jpg" alt="QDS" class="img-fluid" />
                <p>Mit modernster Technologie entwickeln wir einen leistungsstarken und sicheren Webshop, der flexibel erweiterbar ist und auf allen Geräten funktioniert.</p>
            </div>
            <div class="col schatten p-4 m-3">
                <h4>4. Launch & Optimierung</h4>
                <img src="../assets/shopLaunch.jpg" alt="QDS" class="img-fluid" />
                <p>Nach dem erfolgreichen Launch analysieren wir Nutzerdaten, testen verschiedene Elemente und optimieren kontinuierlich die Performance Ihres Shops.</p>
            </div>
        </div>
    
</section>
  <section class="max-w-grid-width text-start py-5">
    <div class="container">
      <h2 class="mb-4 titelRahmen">Warum wir?</h2>
      <h4 class="pb-4 p-2">
        Wir bieten nicht nur erstklassige digitale Lösungen, sondern auch eine enge
        Zusammenarbeit mit unseren Kunden.<br />
        Ihr Erfolg ist unser Ziel!
      </h4>
      <div class="row">
        <div class="col-md-8">
          <ul class="list-group list-group-flush">
            <li class="list-group-item py-3">✔ <b>Maßgeschneiderte Designs:</b> Jeder Online-Shop ist einzigartig. Wir erstellen ein individuelles Design, das perfekt zu Ihrer Marke passt und Ihre Produkte optimal in Szene setzt.</li>
            <li class="list-group-item py-3">✔ <b>Mobile-First-Ansatz:</b> Die meisten Nutzer kaufen heutzutage über mobile Endgeräte ein. Unsere responsiven Designs gewährleisten eine einwandfreie Benutzererfahrung auf Smartphones, Tablets und Desktops.</li>
            <li class="list-group-item py-3">✔ <b>Sicherheit & Performance:</b> Schnelle Ladezeiten und sichere Zahlungsabwicklungen sind essenziell für den Erfolg eines Online-Shops. Wir setzen auf modernste Technologien und optimieren jede Seite für beste Performance.</li>
            <li class="list-group-item py-3">✔ <b>Langfristige Betreuung & Support:</b> Nach dem Launch ist unsere Arbeit nicht vorbei. Wir bieten regelmäßige Updates, technische Wartung und kontinuierliche Verbesserungen, um Ihren Webshop stets auf dem neuesten Stand zu halten.</li>
        </ul>
        </div>
        <div class="col-md-4">
          <img src="../assets/webdesign.jpg" alt="QDS" class="img-fluid rundeEcken" />
        </div>
      </div>

      <h4 class="mt-4">
        Lassen Sie uns gemeinsam Ihre digitale Zukunft gestalten!<br/> Kontaktieren Sie uns für
        eine unverbindliche Beratung.
      </h4>
    </div>
  </section>
  <section><router-link to="/Kontakt" class="btn btn-primary"
    >Kontaktieren Sie uns jetzt!</router-link
  ></section>

  <section>
    <AppFooter />
  </section>
</div>

  <stickybarTelMail />
  <stickyPfeil />
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AppHeader2 from "../components/AppHeader2.vue";
import AppFooter from "../components/AppFooter.vue";
import stickybarTelMail from "../components/stickybarTelMail.vue";
import stickyPfeil from "../components/stickyPfeil.vue";

export default {
  name: "ShopPage",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    AppHeader,
    AppFooter,
    stickybarTelMail,
    stickyPfeil,
    AppHeader2,
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
