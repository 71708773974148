<template>
  <div class="card modalKalkulator">
    <div class="card-header">
      <h1 class="mt-2">Online - Angebotsanfrage</h1>
      <h2>Erstellen Sie eine unverbindliches Anfrage für Ihre neue Webseite</h2>
    </div>
    <div class="card-body text-start">
      <div class="row my-3">
        <div class="col-md-4">
          <div class="input-group">
            <input type="number" class="form-control ModalFormularZahl" value="1" />
            <span class="input-group-text ModalFormularText" id="basic-addon1"
              >Grundpaket</span
            >
            <span class="input-group-text ModalFormularTextPreis" id="basic-addon1"
              > 1.500,00 Euro</span
            >
          </div>
        </div>
        <div class="col-md-8">
          <a
            class="btn btn-primary btn-sm"
            data-bs-toggle="collapse"
            href="#Grundpaket"
            role="button"
            aria-expanded="false"
            aria-controls="Grundpaket"
          >
            <i class="fas fa-angle-double-down"></i>
          </a>
          Enthalten sind alle notwendigen Teile für eine One-Site Homepage
          <div class="collapse text-start modalDetail modalDetail" id="Grundpaket">
            <div class="card card-body">
              <ul>
                <li>
                  <h5>Homepage Startseite</h5>
                  <p class="p-0 m-0">>> Design in Ihren Firmenfarben</p>
                  <p class="p-0 m-0">
                    >> Titelbild mit Logo und Navigation für alle Seiten
                  </p>
                  <p class="p-0 m-0">>> 3 teilige Kurzübersicht Ihres Angebotes</p>
                  <p class="p-0 m-0">
                    >> jeweils ein ausführlicher Beschreibungstext für die Übersichtsseite
                    mit einem Foto
                  </p>
                  <p class="p-0 m-0">
                    >> Fußbereich mit Ihren Fimendaten mit Links zu Impressum, Datenschutz
                    und Cookieinformation
                  </p>
                  <p></p>
                </li>

                <li>Impressum</li>
                <li>Datenschutz</li>
                <li>Cookie-Information</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-md-4">
          <div class="input-group">
            <input
              type="number"
              class="form-control form-control-sm ModalFormularZahl"
              v-model="mail.erweiterungen"
            />
            <span class="input-group-text ModalFormularText" id="basic-addon1"
              >Erweiterungsseite</span
            >
            <span class="input-group-text ModalFormularTextPreis" id="basic-addon1"
              >  350,00 Euro</span
            >
          </div>
        </div>
        <!-- <div class="col-md-1">
          <input
            style="max-width: 50px"
            type="number"
            class="form-control form-control-sm"
            id="exampleFormControlInput1"
            placeholder="2"
            v-model="mail.erweiterungen"
          />
        </div>
        <div class="col-md-3">Erweiterungsseite 350,00 Euro</div> -->
        <div class="col-md-6">
          <a
            class="btn btn-primary btn-sm"
            data-bs-toggle="collapse"
            href="#Erweiterungspaket1"
            role="button"
            aria-expanded="false"
            aria-controls="Erweiterungspaket1"
          >
            <i class="fas fa-angle-double-down"></i>
          </a>
          Weitere Seiten z.B. Angebote, Referenzen oder Mitarbeitersuche
          <div class="collapse text-start modalDetail" id="Erweiterungspaket1">
            <div class="card card-body">
              Weitere Seite
              <ul>
                <li>Eintrag in der Navigationsleiste</li>
                <li>Beschreibungstext für diese Seite</li>
                <li>3 teilige Kurzübersicht über Inhalt dieser Seite</li>
                <li>
                  jeweils ein ausführlicher Beschreibungstext für die Übersichtsseite mit
                  einem Foto
                </li>
                <li>Eintrag als Text mit Link auf der Startseite</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="row my-3">
        <div class="col-md-4">
          <div class="input-group">
            <span class="input-group-text ModalFormularCheckbox" id="basic-addon1">
              <input type="checkbox" class="form-check-input" v-model="mail.kontakt" />
            </span>
            <span class="input-group-text ModalFormularText" id="basic-addon1"
              >Kontaktformular</span
            >
            <span class="input-group-text ModalFormularTextPreis" id="basic-addon1"
              >300,00 Euro</span
            >
          </div>
        </div>

        <div class="col-md-6">
          <a
            class="btn btn-primary btn-sm"
            data-bs-toggle="collapse"
            href="#Kontaktformular"
            role="button"
            aria-expanded="false"
            aria-controls="Kontaktformular"
          >
            <i class="fas fa-angle-double-down"></i>
          </a>
          Bieten Sie Ihren Kunden eine einfache Kontaktmöglichkeit
          <div class="collapse text-start modalDetail" id="Kontaktformular">
            <div class="card card-body">
              Sie erhalten eine Mail mit folgenden Angaben
              <ul>
                <li>Name</li>
                <li>Email</li>
                <li>Telefonnummer(optional)</li>
                <li>Mitteilungstext</li>
                <li>Einverständnis zur Datenschutz</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-3">
        
        <div class="col-md-4">
          <div class="input-group">
            <span class="input-group-text ModalFormularCheckbox" id="basic-addon1">
              <input type="checkbox" class="form-check-input" v-model="mail.bewerbung" />
            </span>

            <span class="input-group-text ModalFormularText" id="basic-addon1"
              >Onlinebewerbung
              </span
            >
            <span class="input-group-text ModalFormularTextPreis" id="basic-addon1"
              >400,00 Euro</span
            >
          </div>
          <div class="input-group mb-3" v-if="mail.bewerbung == true">
            <input
              style="max-width: 50px"
              type="number"
              class="form-control form-control-sm"
              id="exampleFormControlInput1"
              placeholder="1"
            />
            <div class="input-group-text">
              <span class="checkboxLabelKlein">Jobs (ab 2. je 100,00 Euro)</span>
            </div>
          </div>

        </div>
     
       
        <div class="col-md-6">
          <a
            class="btn btn-primary btn-sm"
            data-bs-toggle="collapse"
            href="#Onlinebewerbung"
            role="button"
            aria-expanded="false"
            aria-controls="Onlinebewerbung"
          >
            <i class="fas fa-angle-double-down"></i>
          </a>
          Erhalten Sie Bewerbungen direkt über Ihre Homepage
          <div class="collapse text-start modalDetail" id="Onlinebewerbung">
            <div class="card card-body">
              Sie erhalten eine Mail mit folgenden Angaben
              <ul>
                <li>Anrede</li>
                <li>Name</li>
                <li>Email</li>
                <li>Telefonnummer(optional)</li>
                <li>Adresse(optional)</li>
                <li>Upload für Anschreiben(optional)</li>
                <li>Upload für Lebenslauf(optional)</li>
                <li>Einverständnis zur Datenschutz</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="row my-3">
        <div class="col-md-4">
          <div class="input-group">
            <input
              type="number"
              class="form-control form-control-sm ModalFormularZahl"
              v-model="mail.download"
            />
            <span class="input-group-text ModalFormularText" id="basic-addon1"
              >PDF - Download </span
            >
            <span class="input-group-text ModalFormularTextPreis" id="basic-addon1"
              >25,00 Euro</span
            >
          </div>
        </div>
        
        <div class="col-md-8">
          <a
            class="btn btn-primary btn-sm"
            data-bs-toggle="collapse"
            href="#Download"
            role="button"
            aria-expanded="false"
            aria-controls="Download"
          >
            <i class="fas fa-angle-double-down"></i>
          </a>
          Jeder Download enhält eine Vorschaufenster mit Druck oder Speichermöglichkeit
          <div class="collapse text-start modalDetail" id="Download">
            <div class="card card-body">
              Bieten Sie Ihren Kunden weitere Informationen zu Download an.
              <p>zum Beispiel:</p>
              <ul>
                <li>Zertifikate</li>
                <li>Kataloge</li>
                <li>Inhaltsbeschreibungen</li>
                <li>Maßtabellen</li>
                <li>oder was auch immer</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-3">
        
        <div class="col-md-4">
          <div class="input-group">
            <span class="input-group-text ModalFormularCheckbox" id="basic-addon1">
              <input type="checkbox" class="form-check-input" v-model="mail.inter" />
            </span>
            <span class="input-group-text ModalFormularText" id="basic-addon1"
              >verschi. Sprachen </span
            >
            <span class="input-group-text ModalFormularTextPreis " id="basic-addon1"
              >{{ getInterpreis() }} Euro</span
            >
          </div>
        </div>
        
        <div class="col-md-6">
          Ihre Homepage in mehreren Sprachen ( Deutsch/Englisch ), pro weitere Sprache
          400,00 Euro
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 offset-md-1">
          Gesamtpreis für Ihre Webseite aus dieser Anfrage voraussichtlich<br />
          <span class="fw-bolder"> nur {{ getGesamtPreis() }} Euro </span
          ><span class="checkboxLabelKlein">(zzgl. {{ getMWST() }} MwSt)</span>
        </div>
      </div>

      <div class="row contact_form bg-white">
        <div class="col-md-6 offset-md-1 pt-3">
          <p>Gerne erstellen wir Ihnen ein individuelles Angebot.</p>
        </div>
      </div>

      <div class="row contact_form bg-white">
        <div class="col-md-6 offset-md-1 pt-3">
          <label class="textLabel" for="name">Name:*</label>
          <input
            type="text"
            name="name"
            class="formtextField"
            v-bind:class="background.name"
            placeholder="Mein Name"
            v-model="mail.name"
          />
        </div>
      </div>
      <div class="row contact_form bg-white">
        <div class="col-md-6 offset-md-1 pt-3">
          <label class="textLabel" for="email">Email:*</label>
          <input
            type="text"
            name="email"
            class="formtextField"
            placeholder="max@example.de"
            v-model="mail.email"
            v-bind:class="background.email"
          />
        </div>
      </div>
      <div class="row contact_form bg-white">
        <div class="col-md-6 offset-md-1 pt-3">
          <label class="textLabel" for="email">Telefon:</label>
          <input
            type="text"
            name="telefon"
            class="formtextField"
            placeholder="0123 456789"
            v-model="mail.telefon"
          />
        </div>
      </div>
      <div class="row contact_form bg-white">
        <div class="col-md-6 offset-md-1 pt-3">
          <input
            type="checkbox"
            name="datenschutz"
            class="checkbox"
            v-model="mail.datenschutz"
            v-bind:class="background.datenschutz"
          />*<span
            class="ps-5 checkboxLabel"
            v-bind:class="background.datenschutz"
            for="email"
          >
            Die Angaben zum
            <router-link to="/Datenschutz" class="">Datenschutz</router-link>
            habe ich gelesen .
          </span>
        </div>
      </div>
      <div class="row contact_form bg-white">
        <div class="col-md-6 pt-3"></div>
      </div>
      <div class="row contact_form bg-white">
        <div class="col-md-6 offset-md-1 pt-3">
          <p>
            Wir melden uns so schnell wie möglich bei Ihnen zurück. Wenn Sie uns Ihre
            Telefonnummer verraten, auch auf diesem Weg.
          </p>
        </div>
      </div>
      <div class="row contact_form bg-white">
        <div class="col-md-6 pt-3">
          <div class="row justify-content-center"></div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-4 text-start">
          <button class="submit m-0" type="submit" @click="sendMail()">
            Anfrage senden
          </button>
          <span class="required_notification px-2">* notwendige Eingaben</span>
          <p>{{ mail.gesendet }}</p>
        </div>
        <div class="col-md-2">
          <button class="btn btn-outline-danger" @click="close()">Schließen</button>
        </div>
      </div>
    </div>

    <div class="modal-footer"></div>

    <div class="obenRechts">
      <button class="btn btn-outline-danger" @click="close()">X</button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { closeModal } from "jenesius-vue-modal";

export default {
  name: "KalkulatorModal",

  data() {
    return {
      newSite: null,
      visi: false,
      mail: {
        name: "",
        email: "",
        telefon: "",
        datenschutz: false,
        gesendet: "",

        erweiterungen: 0,
        kontakt: false,
        bewerbung: false,
        jobs: 0,
        download: 0,

        inter: 0,
      },
      gesamtpreis: 1500,

      background: {
        name: "",
        email: "",
        message: "",
        datenschutz: "",
      },
    };
  },
  components: {},

  methods: {
    getGesamtPreisNum() {
      const mail = this.mail;
      let ge = 1500 + mail.erweiterungen * 350;
      if (mail.kontakt == true) {
        ge = ge + 300;
      }
      if (mail.bewerbung == true) {
        ge = ge + 400;
      }

      if (mail.jobs > 1) {
        ge = ge + (mail.jobs - 1) * 100;
      }
      if (mail.download > 0) {
        ge = ge + mail.download * 25;
      }
      if (mail.inter == true) {
        ge = ge + this.getInterpreisNum();
      }

      return ge;
    },
    getGesamtPreis() {
      let ge = this.getGesamtPreisNum();
      return ge.toFixed(2).replace(".", ",");
    },
    getInterpreisNum() {
      let interPreis = 800 + this.mail.erweiterungen * 400;

      return interPreis;
    },
    getInterpreis() {
      let ge = this.getInterpreisNum();
      return ge.toFixed(2).replace(".", ",");
    },

    getMWST() {
      return (this.getGesamtPreisNum() * 1.19 - this.getGesamtPreisNum())
        .toFixed(2)
        .replace(".", ",");
    },
    checkEingaben() {
      let re = true;
      if (this.mail.name == "") {
        this.background.name = "border border-2 border-danger";
        re = false;
      } else {
        this.background.name = "border border-2 border-success";
      }
      if (this.mail.email == "") {
        this.background.email = "border border-2 border-danger";
        re = false;
      } else {
        this.background.email = "border border-2 border-success";
      }

      if (this.mail.datenschutz == false) {
        this.background.datenschutz = "bg-danger";
        re = false;
      } else {
        this.background.datenschutz = "bg-success";
      }

      return re;
    },

    close() {
      closeModal();
    },
    sendMail() {
      if (this.checkEingaben()) {
        const axiosConfig = {
          headers: {
            Accept: "text/plain",
            "Content-Type": "text/plain",
          },
        };
        const payload = {
          empfaenger: "info@pillarsoft.de",
          name: this.mail.name,
          email: this.mail.email,
          telefon: this.mail.telefon,
          erweiterungen: this.mail.erweiterungen,
          bewerbung: this.mail.bewerbung,
          jobs: this.mail.jobs,
          kontakt: this.mail.kontakt,
          download: this.mail.download,
        };
        let mail = this.mail;
        let background = this.background;
        axios
          .post("https://pillar.de/mailrelay/post_attachment.php", payload, axiosConfig)
          .then((response) => {
            console.log("success", response.data);
            mail.gesendet = "Ihre Kontaktdaten wurden erfolgreich versendet";
            mail.name = "";
            background.name = "";
            mail.email = "";
            background.email = "";
            mail.message = "";
            background.message = "";
            mail.datenschutz = false;
            background.datenschutz = "";
          })
          .catch((error) => {
            console.log(error.response);
            mail.gesendet =
              "Ihre Kontaktdaten konnten leider nicht gesendet werden. Bitte rufen Sie uns an.";
          });
      }
    },
    toggleDdown() {
      this.visi = true;
      setTimeout(() => (this.visi = false), 2000);
    },

    ifAktuell() {
      if (this.activeScreen == "/Kontakt") {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {},
};
</script>
