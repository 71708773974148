<template>
  <div class="backgrund1">
    <AppHeader />
    <div class="container">
      <h1 class="mainTitel">
        Software <br />
        für kleine und <br />mittelständige Unternehmen
      </h1>
      <h3 class="mainTitel2">
        DEVELOPMENT / ENGINEERING / SUPPORT<br />
        WEBDESIGN / WEBSHOPS
      </h3>
    </div>
  </div>
  <div class="container">
    <AppHeader2 />
   
    <section class="max-w-grid-width"> <!--software-->
      <div class="row m-auto justify-content-between">
        <div class="col-md-4 NurKleinansicht">
          <img src="../assets/software.jpg" alt="QDS" class="img-fluid rundeEcken" />
        </div>
        <div class="col-md-8 text-start p-4">
          <router-link to="/software" class="nav-link">
            <h2><span class="titelRahmen">Softwarelösungen</span></h2>
          </router-link>

          <h4 class="pt-3">
            Wenn Sie ein Problem lösen wollen, aber keine passende Software finden.
          </h4>
          <p>
            Software gibt es wie Sand am Meer. Die meisten Programme versuchen möglichst
            alle Probleme auf einmal zu lösen. Das führt in den meisten Fällen dazu, dass
            die Programme völlig überladen, unübersichtlich und nur bedingt zu gebrauchen
            sind.<br />
            <br />
            Genauso wie Ihr Unternehmen einzigartig ist, sind auch die Anforderungen an
            Ihre Software einzigartig. <br />
          </p>
          <p>
            <span class="fw-bolder">PILLARSOFT</span> erstellt Ihnen die Software, welche
            Sie für die optimale Organisation Ihres Unternehmens brauchen.
          </p>

          <ul>
            <li>Verknüpfen Sie Ihre Webseite mit Ihrer internen Kommunikation.</li>
            <li>Stellen Sie Ihren Kunden individuelle Informationen zur Verfügung.</li>
            <li>Reduzieren Sie den Papierkrieg in Ihrem Büro auf das nötigste.</li>
            <li>Moderne Dokumentenablagesysteme helfen Ihnen Zeit und Geld zu sparen.</li>
          </ul>
          Dies sind typische Dinge, welche sich mit den Jahren ändern und gerade bei
          rechtlichen Dingen richtig teuer werden können, wenn sie falsch sind.
        </div>
        <div class="col-md-4 px-md-5 NurGrossansicht">
          <img src="../assets/software.jpg" alt="QDS" class="img-fluid rundeEcken" />
        </div>
      </div>
    </section>
    <section class="max-w-grid-width"> <!--webdesign-->
      <div class="row m-auto justify-content-between">
        <div class="col-md-4 px-md-3">
          <img src="../assets/webdesign.jpg" alt="QDS" class="img-fluid rundeEcken" />

          <button
            type="button"
            class="btn bg-success text-white fw-bolder px-5"
            @click="openModalKalkulator"
          >
            Online Kalkulation und Anfrage
          </button>
        </div>
        <div class="col-md-7 text-start px-md-5">
          <router-link to="/web" class="nav-link">
            <h2><span class="titelRahmen">Webdesign</span></h2>
          </router-link>

          <h4 class="pt-3">
            Ist ihr Webauftritt veraltet und funktioniert nicht auf mobilen Geräten?
          </h4>
          <p>
            Zu einem modernen Unternehmen, gehört auch immer eine moderne Webseite.
            <br />Daher sollten Sie spätestens alle 4-5 Jahre Ihre Webseite einmal
            kritisch betrachten. Entspricht das, was Sie sehen, noch dem, was Sie in der
            Öffentlichkeit repräsentieren möchten?
          </p>
          <p>
            <span class="fw-bolder">PILLARSOFT</span> hilft Ihnen Ihre Webseite wieder auf
            den aktuellen Stand der Technik und zu einem modernen Design zu bringen.
          </p>
          <p>
            Helfen Sie <I></I>hren Kunden mit Ihnen in Verbindung zu treten mit Hilfe von:
          </p>

          <ul>
            <li>Telefonnummern</li>
            <li>Mailadressen</li>
            <li>Kontaktformulare</li>
            <li>Impressum</li>
            <li>Datenschutz</li>
          </ul>
          Dies sind typische Punkte, welche sich mit den Jahren ändern und gerade bei
          rechtlichen Dingen richtig teuer werden können, wenn sie falsch sind.
        </div>
      </div>
    </section>
    
    <section class="max-w-grid-width">  <!--qds-->
      <div class="row m-auto justify-content-between">
        <div class="col-md-4 NurKleinansicht">
          <img src="../assets/qds.jpg" alt="QDS" class="img-fluid rundeEcken" />
        </div>
        <div class="col-md-8 text-start">
          <router-link to="/qds" class="nav-link">
            <h2><span class="titelRahmen">QDS - Qualitätsmanagement</span></h2>
          </router-link>

          <h4 class="py-3">
            Dokumentation von Prüf- und Testergebnissen in der Kleinserien- und
            Musterproduktion
          </h4>
          <p>
            Erstellen Sie einfach Prüfabläufe für ihre Qualitätssicherung und
            Dokumentation
          </p>

          <p>
            Für jedes Modell in Ihrer Fertigung können eigene Szenarien angelegt werden
          </p>
          <p>Führen Sie Prüfungen nach festgelegter Häufigkeit durch</p>
          <ul>
            <li>Jedesmal bei einem Arbeitsschritt</li>
            <li>Regelmäßig alle x Fertigungen</li>
            <li>Zufällig alle x Fertigungen</li>
          </ul>
        </div>
        <div class="col-md-4 px-md-5 NurGrossansicht">
          <img src="../assets/qds.jpg" alt="QDS" class="img-fluid rundeEcken" />
        </div>
      </div>
    </section>

    <section class="max-w-grid-width">  <!--shops-->
      <div class="row m-auto justify-content-between">
        <div class="col-md-4">
          <img src="../assets/webshop.jpg" alt="QDS" class="img-fluid rundeEcken" />
        </div>
        <div class="col-md-7 px-md-4 text-start p-4">
          <router-link to="/shop" class="nav-link">
            <h2><span class="titelRahmen">Webshops</span></h2>
          </router-link>
          <h4 class="pt-3">
            Sie wollen Ihre tollen Produkte in einem Webshop verkaufen ?
          </h4>
          <p>
            Webshops sind heutzutage die häufigste Visualisierungsform von Marken. <br />
            Eine schlechte, veraltete oder irreführende Website enttäuscht daher nicht nur
            Ihre Besucher. Sie richtet auch einen großen Imageschaden an und führt dazu,
            dass Ihr Geschäftserfolg hinter seinen Möglichkeiten zurückbleibt. <br />
            Es gibt mittlerweile mehr Mobile Geräte, wie Handys, Smartphones und Tablets,
            als PC's und Notebooks. Aber nur auf neueren Webseiten ist die Darstellung mit
            diesen Geräten möglich.
          </p>
          <p>
            <span class="fw-bolder">PILLARSOFT</span> hilft Ihnen Ihre Webseite wieder auf
            den aktuellen Stand der Technik und zu einem modernen Design zu bringen.
            <br />Helfen Sie ihren Kunden mit Ihnen in Verbindung zu treten.
          </p>

          <ul>
            <li>Telefonnummern</li>
            <li>Mailadressen</li>
            <li>Kontaktformulare</li>
            <li>Impressum</li>
            <li>Datenschutz</li>
          </ul>
          Dies sind typische Dinge, welche sich mit den Jahren ändern und gerade bei
          rechtlichen Dingen richtig teuer werden können, wenn sie falsch sind.
        </div>
      </div>
    </section>
    <section class="max-w-grid-width">
      <h2>Warum PILLARSOFT? Ihr Partner für zukunftssichere Softwarelösungen</h2>
      <p>
        In einer digitalen Welt, die sich ständig verändert, brauchen Unternehmen einen
        starken Partner an ihrer Seite. Pillarsoft steht für Innovation, Zuverlässigkeit
        und maßgeschneiderte Softwarelösungen, die Ihr Geschäft auf das nächste Level
        heben.
      </p>
      <div class="row m-auto justify-content-between">
        <div class="col-md-4 p-4">
          <img src="../assets/Innovation.jpg" alt="QDS" class="img-fluid rundeEcken" />
          <h3>Innovation</h3>
          <p>
            Wir entwickeln Softwarelösungen, die Ihr Unternehmen fit für die Zukunft
            machen. Unsere Experten arbeiten mit den neuesten Technologien und Methoden,
            um Ihnen innovative Lösungen zu bieten.
          </p>
        </div>
        <div class="col-md-4 p-4">
          <img
            src="../assets/Zuverlaessigkeit.jpg"
            alt="QDS"
            class="img-fluid rundeEcken"
          />
          <h3>Zuverlässigkeit</h3>
          <p>
            Bei Pillarsoft können Sie sich auf uns verlassen. Wir halten unsere
            Versprechen und liefern Ihnen maßgeschneiderte Softwarelösungen, die Ihren
            Anforderungen entsprechen.
          </p>
        </div>
        <div class="col-md-4 p-4">
          <img
            src="../assets/Massgeschneidert.jpg"
            alt="QDS"
            class="img-fluid rundeEcken"
          />
          <h3>Maßgeschneidert</h3>
          <p>
            Jedes Unternehmen ist einzigartig und hat individuelle Anforderungen. Wir
            entwickeln Softwarelösungen, die perfekt auf Ihre Bedürfnisse zugeschnitten
            sind.
          </p>
        </div>
      </div>
    </section>
    <section class="max-w-grid-width px-2 text-start">
      <h2 class="text-uppercase">digitale Transformation</h2>
      <p>
        Unabhängig davon, wie weit Ihre digitale Transformation fortgeschritten ist oder
        in welcher Branche Sie tätig sind – wir sind Ihr vertrauenswürdiger Partner für
        die Entwicklung geschäftskritischer Software und innovativer Lösungen.
      </p>
      <div class="row m-auto justify-content-between py-3">
        <div class="col-md-4 rundeEcken">
          <h5>Ihr zuverlässiger Partner für digitale Innovationen</h5>
        </div>
        <div class="col-md-7 text-start">
          <p>
            Ganz gleich, ob Ihr Unternehmen am Anfang der digitalen Transformation steht
            oder bereits weit fortgeschritten ist – wir begleiten Sie mit
            maßgeschneiderter Softwareentwicklung und zukunftsweisenden Lösungen, die Ihr
            Geschäft voranbringen.
          </p>
        </div>
      </div>
      <div class="row m-auto justify-content-between py-3">
        <div class="col-md-4 rundeEcken">
          <h5>Maßgeschneiderte Software für Ihren Erfolg</h5>
        </div>
        <div class="col-md-7 text-start">
          <p>
            Branchenübergreifend und unabhängig vom Digitalisierungsgrad bieten wir
            innovative, geschäftskritische Softwarelösungen, die Ihre Prozesse optimieren
            und Ihnen Wettbewerbsvorteile verschaffen.
          </p>
        </div>
      </div>
      <div class="row m-auto justify-content-between py-3">
        <div class="col-md-4 rundeEcken">
          <h5>Digitale Transformation mit einem starken Partner</h5>
        </div>
        <div class="col-md-7 text-start">
          <p>
            Ob Industrie, Handel oder Dienstleistung – wir verstehen die Herausforderungen
            Ihrer Branche und entwickeln leistungsstarke Softwarelösungen, die Ihre
            Geschäftsprozesse effizienter, sicherer und skalierbarer machen.
          </p>
        </div>
      </div>
      <div class="row m-auto justify-content-between py-3">
        <div class="col-md-4 rundeEcken">
          <h5>Individuelle Lösungen für Ihre digitale Zukunft</h5>
        </div>
        <div class="col-md-7 text-start">
          <p>
            Von der Prozessautomatisierung bis hin zu komplexen Softwarearchitekturen –
            wir liefern Ihnen passgenaue Lösungen, die Ihre digitale Transformation
            nachhaltig unterstützen.
          </p>
        </div>
      </div>
      <div class="row m-auto justify-content-between py-3">
        <div class="col-md-4 rundeEcken">
          <h5>Technologie trifft Expertise: Ihr Weg in die digitale Zukunft</h5>
        </div>
        <div class="col-md-7 text-start">
          <p>
            Egal, ob Sie gerade erst mit der Digitalisierung beginnen oder Ihre bestehende
            IT-Landschaft optimieren möchten – mit unserer langjährigen Erfahrung und
            innovativen Technologien sind wir Ihr zuverlässiger Entwicklungspartner.
          </p>
        </div>
      </div>
      <div class="row m-auto justify-content-between py-3">
        <div class="col-md-4 rundeEcken">
          <h5>Zukunftssichere Software für Ihr Unternehmen</h5>
        </div>
        <div class="col-md-7 text-start">
          <p>
            Unabhängig von Ihrer Branche oder dem aktuellen Stand Ihrer Digitalisierung
            entwickeln wir innovative, maßgeschneiderte Softwarelösungen, die Effizienz
            steigern, Sicherheit gewährleisten und Ihr Unternehmen nachhaltig für die
            Zukunft rüsten.
          </p>
        </div>
      </div>
    </section>

    <section>
      <router-link to="/Kontakt" class="btn btn-primary"
        >Kontaktieren Sie uns jetzt!</router-link
      >
    </section>
    <section>
      <AppFooter />
    </section>
  </div>
  <stickybarTelMail />
  <stickyPfeil />
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AppHeader2 from "../components/AppHeader2.vue";
import AppFooter from "../components/AppFooter.vue";
import stickybarTelMail from "../components/stickybarTelMail.vue";
import stickyPfeil from "../components/stickyPfeil.vue";

import { openModal } from "jenesius-vue-modal";
import ModalKalkulator from "../components/ModalKalkulator.vue";

export default {
  name: "MainPage",

  data() {
    return {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      newSite: null,
    };
  },
  components: {
    AppHeader,
    AppHeader2,
    AppFooter,
    stickybarTelMail,
    stickyPfeil,
    //  carouselHeader,
  },
  methods: {
    async openModalKalkulator() {    
   const modal= await openModal(ModalKalkulator);

      modal.onclose = () => {        
        return true;
      };
    },

  },
  computed: {},

  mounted() {
    window.onresize = () => {
      this.windowHeight = window.innerHeight;
    };
  },
};
</script>

<style scoped></style>
