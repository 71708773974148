<template>
  <div class="card pt-3 hintergrundHell" style="width: 35rem">
    <div class="card-body p-3">
      <div class="cardHeader">Cookie - Informationen</div>

      <div class="card-text pt-1">
        <div class="row text-start">
          <div class="col">
            Um Ihnen ein optimales Erlebnis auf unserer Website zu bieten, nutzen wir
            essenzielle Technologien. Diese sind unerlässlich für die grundlegende
            Funktionalität der Website und werden automatisch aktiviert, wenn Sie unsere
            Seite besuchen. Hier finden Sie Details zu den essenziellen Technologien, die
            wir verwenden:
          </div>
        </div>
        <div class="row text-start pt-4">
          <div class="col-10"><h5 class="fw-bold">Essenziell</h5></div>
          <div class="col-1">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
              />
            </div>
          </div>
        </div>
        <div class="row text-start">
          <div class="col">
            Essenzielle Cookies ermöglichen grundlegende Funktionen und sind für die
            einwandfreie Funktion der Website erforderlich.
          </div>
        </div>
        <div class="row text-start">
          <div class="col">
            <ul>
              <li>
                <div class="row">
                  <div class="col-10 fw-bold">Analyse und Auswertung</div>
                  <div class="col-1">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                      />
                    </div>
                  </div>
                </div>

                Google Tag zur Analyse der Homepagebesuche ohne Nutzerdaten
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-footer pt-2">
        <button
          type="button"
          class="btn btn-outline-danger mx-3"
          @click="closeCookieDetail()"
        >
          Schließen
        </button>
        <button type="button" class="btn btn-danger" @click="setCookie()">
          Hinweis schließen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { closeModal } from "jenesius-vue-modal";
export default {
  name: "ModalsKassenbuchungEdit",
  methods: {
    closeCookieDetail() {
      closeModal();
    },
    setCookie() {
      // Cookie setzen
      this.$cookies.set("HomepageCookiePillarSoft", this.$t("adresseName"));
      closeModal();
    },
  },
};
</script>
