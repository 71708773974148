<template>
  <nav class="navbar sticky-top navbar-expand-lg p-0 bg-light">
    
    <div class="container-fluid d-flex justify-content-between align-items-center ">
      <!-- Logo -->
      <router-link to="/" class="nav-link d-flex align-items-center ms-md-5 NurGrossansicht">
        <img src="../assets/pillarsoft.png" class="HeaderLogo2" alt="LogoLTB" />
      </router-link>

      <!-- Hamburger Menu -->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Navigationsmenü -->
      <div class="collapse navbar-collapse  navbarUL" id="navbarSupportedContent">
        <ul class="navbar-nav mb-2 mb-lg-0 text-uppercase">
          <li class="nav-item pe-4">
            <router-link to="/" class="nav-link2"> HOME </router-link>
          </li>
          <li class="nav-item px-4">
            <router-link to="/software" class="nav-link2"> Software </router-link>
          </li>
          <li class="nav-item px-4">
            <router-link to="/qds" class="nav-link2"> QDS </router-link>
          </li>
          <li class="nav-item px-4">
            <router-link to="/web" class="nav-link2"> Webdesign </router-link>
          </li>
          <li class="nav-item px-4">
            <router-link to="/shop" class="nav-link2"> Shops </router-link>
          </li>
          
        </ul>

        <!-- Rechte Navigation -->
        <ul class="navbar-nav mb-2 mb-lg-0 text-uppercase navRechts">
          
          <li class="navKontakt px-2">
            <router-link to="/Kontakt" class="nav-link2"> Kontakt </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>


<script>
export default {
  name: "TheNavbar",
  computed: {},
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    },
   
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  created() {},
};
</script>
